import { Swiper, Navigation, Pagination } from "swiper/js/swiper.esm.js";
import React, { useEffect, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import styled from "styled-components";

import "swiper/css/swiper.min.css";

const Overlay = styled.div`
  background: #00000060;
  border-radius: 0.5rem;
  color: #fff;
  margin-bottom: 4rem;
  margin-left: 2rem;
  position: absolute;
  bottom: 0;
  padding: 0.25rem 0.75rem;
  text-shadow: 1px 1px 1px #000;
`;

const Subheading = styled.h2`
  font-weight: 400;
  letter-spacing: -0.05rem;
`;

const SwiperLink = styled.a`
  color: #fff;
  display: block;
  margin-top: 1rem;
`;

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "Brancaster Staithe garden prep.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image2: file(
        relativePath: { eq: "Brancaster Staithe - seaside front garden.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image3: file(relativePath: { eq: "Dersingham front garden.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image4: file(relativePath: { eq: "Dersingham main garden.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image5: file(relativePath: { eq: "Gayton front garden before.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image6: file(relativePath: { eq: "Gayton front garden after.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image7: file(relativePath: { eq: "Magdalen prep.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image8: file(relativePath: { eq: "Magdalen finished garden.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image9: file(relativePath: { eq: "Snettisham blank canvas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image10: file(relativePath: { eq: "Snettisham garden prep.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image11: file(relativePath: { eq: "Snettisham old terrace.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image12: file(relativePath: { eq: "Snettisham rebuilt terrace.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      image13: file(relativePath: { eq: "Snettisham courtyard garden.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  Swiper.use([Navigation, Pagination]);

  const swiper = useRef();
  const swiperContainer = useRef();
  const nextEl = useRef();
  const prevEl = useRef();
  const pagination = useRef();

  useEffect(() => {
    swiper.current = new Swiper(swiperContainer.current, {
      grabCursor: true,
      navigation: {
        nextEl: nextEl.current,
        prevEl: prevEl.current
      },
      pagination: {
        el: pagination.current,
        clickable: true
      },
      spaceBetween: 30
    });
  }, []);

  return (
    <>
      <Subheading>Example Projects</Subheading>
      <p>Swipe through some of our previous projects in the gallery below.</p>
      <div className="swiper-container" ref={swiperContainer}>
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <Img fluid={data.image3.childImageSharp.fluid} />
            <Overlay>Dersingham front garden</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image4.childImageSharp.fluid} />
            <Overlay>Dersingham back garden</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image1.childImageSharp.fluid} />
            <Overlay>Brancaster Staithe front garden (before)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image2.childImageSharp.fluid} />
            <Overlay>Brancaster Staithe front garden (after)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image5.childImageSharp.fluid} />
            <Overlay>Gayton front garden (during)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image6.childImageSharp.fluid} />
            <Overlay>Gayton front garden (after)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image7.childImageSharp.fluid} />
            <Overlay>Magdalen garden (during)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image8.childImageSharp.fluid} />
            <Overlay>Magdalen garden (after)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image9.childImageSharp.fluid} />
            <Overlay>Snettisham courtyard garden (blank canvas)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image10.childImageSharp.fluid} />
            <Overlay>Snettisham courtyard garden (during)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image13.childImageSharp.fluid} />
            <Overlay>Snettisham courtyard garden (after)</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image11.childImageSharp.fluid} />
            <Overlay>Snettisham old terrace</Overlay>
          </div>
          <div className="swiper-slide">
            <Img fluid={data.image12.childImageSharp.fluid} />
            <Overlay>
              Snettisham rebuilt terrace
              <SwiperLink
                href=""
                onClick={e => {
                  e.preventDefault();
                  swiper.current.slideTo(0);
                }}
              >
                Jump to the beginning?
              </SwiperLink>
            </Overlay>
          </div>
        </div>
        <div
          className="swiper-pagination swiper-pagination-white"
          ref={pagination}
        ></div>
        <div
          className="swiper-button-prev swiper-button-white"
          ref={prevEl}
        ></div>
        <div
          className="swiper-button-next swiper-button-white"
          ref={nextEl}
        ></div>
      </div>
    </>
  );
};

export default Gallery;
