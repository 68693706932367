import { useStaticQuery, graphql } from "gatsby";
import SiteHeader from "./header";
import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";

import "./layout.css";
import "./style.css";

const Footer = styled.footer`
  margin-top: 2rem;
  padding: 2rem;
  text-align: center;
`;

const Main = styled.main`
  display: flex;
  justify-content: center;
`;

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <SiteHeader siteTitle={data.site.siteMetadata.title} />
      <Main>{children}</Main>
      <Footer>© {new Date().getFullYear()} Ilex Garden Designs</Footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
