import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import styled from "styled-components";

const Header = styled.header`
  align-items: flex-start;
  display: flex;
  height: 90vh;
  justify-content: center;
  position: relative;
  width: 100%;
`;

const BgImg = styled(Img)`
  box-shadow: 0px 3px 10px #999;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  @media (min-width: 768px) {
    border-bottom-left-radius: 25% 1.5%;
    border-bottom-right-radius: 25% 1.5%;
  }
`;

const Card = styled.div`
  background: #00000040;
  border-radius: 0;
  margin-top: 0;
  padding: 1rem;
  position: relative;

  @media (min-width: 768px) {
    border-radius: 1rem;
    margin-top: 2rem;
  }
`;

const Title = styled.h1`
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  font-weight: 400;
  margin-bottom: 4rem;
  text-shadow: 2px 2px 2px black;

  @media (min-width: 768px) {
    font-size: 4rem;
  }
`;

const Benefits = styled.ul`
  color: #fff;
  font-size: 1.5rem;
  line-height: 1.5rem;
  text-shadow: 2px 2px 2px black;
  list-style: none;
  margin: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 2rem;
    line-height: 2rem;
  }
`;

const Benefit = styled.li`
  :last-of-type {
    margin: 0;
  }
`;

const SiteHeader = ({ siteTitle }) => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "Dersingham patio .jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2560) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Header>
      <Card>
        <Title>{siteTitle}</Title>
        <Benefits>
          <Benefit>Add value to your home</Benefit>
          <Benefit>Beautiful gardens designed to suit your lifestyle</Benefit>
          <Benefit>Traditional</Benefit>
          <Benefit>Contemporary</Benefit>
          <Benefit>Low maintenance</Benefit>
          <Benefit>Environmentally and wildlife friendly</Benefit>
        </Benefits>
      </Card>
      <BgImg fluid={data.image.childImageSharp.fluid} />
    </Header>
  );
};

export default SiteHeader;
