import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const Summerhouse = () => {
  const data = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "Dersingham garden summerhouse.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Img
      fluid={data.image.childImageSharp.fluid}
      alt="Garden summerhouse"
      style={{
        marginBottom: "2rem"
      }}
    />
  );
};

export default Summerhouse;
