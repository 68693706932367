import React from "react";

import Gallery from "../components/gallery";
import Layout from "../components/layout";
import Summerhouse from "../components/summerhouse";
import SEO from "../components/seo";
import styled from "styled-components";

const Col = styled.div`
  max-width: 1200px;
  margin-top: 2rem;
  overflow: hidden;
`;

const Subheading = styled.h2`
  font-weight: 400;
  letter-spacing: -0.05rem;
`;

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Col>
      <p>
        The essence of a garden designer is to please the client by producing a
        design in sympathy with the house and its environment while avoiding
        costly mistakes. We offer a bespoke garden design service mainly in
        North and West Norfolk, involving a site visit to discuss the clients
        requirements. This can be followed by a full garden design or
        suggestions enabling the client to carry out the work themselves. We can
        offer full planting plans or advice on planting and other garden
        queries, be they about design, planting or hard landscaping. I work with
        an expert landscaping contractor, Paul, and his team, who carry out the
        construction and building of the garden.
      </p>
      <Subheading>About Us</Subheading>
      <Summerhouse />
      <p>
        In line with current thinking, Gwen’s current emphasis is on informality
        and sustainability. Her own award winning cottage style garden has year
        round interest. Mixed borders of shrubs, perennials and other structural
        plants help, with many plants self-seeding giving a soft cottage garden
        effect. Watering the garden and the use of pesticides are avoided if
        possible, thus only those plants which enjoy the conditions provided
        remain healthy and survive. She also endeavors to have flowering plants
        throughout the year as a nectar source for bees and other insects. Since
        following a pesticide free regime, she has had few problems with garden
        pests as the beneficial wildlife species help to keep these in check.
      </p>
      <p>
        Gwen has had a life-long interest in plants and gardens. She graduated
        from Nottingham University with a B.Sc. in Horticulture after which she
        worked as a horticulturalist in Tanzania with Voluntary Service Overseas
        (VSO). This was followed by a number of years in the agrochemical
        business. She then decided to follow her earlier ambition of becoming a
        garden designer, studying with the internationally renowned garden
        designer, John Brookes. A few years later she took a full-time
        post-graduate diploma course at one of the top colleges in the country,
        The Oxford College of Garden Design run by Duncan Heather. Gwen has been
        running a successful garden design business for a number of years.
      </p>
      <Subheading>Fees</Subheading>
      <p>
        Designs from £350 plus expenses <br />
        Gardening advice: £20/ half hour plus expenses. Minimum charge: £40
      </p>
      <Subheading>Contact Details</Subheading>
      <p>
        Gwen Leary
        <br />
        Ilex Garden Designs
        <br />
        12 Sandringham Road
        <br />
        Dersingham
        <br />
        King’s Lynn
        <br />
        PE31 6LL
        <br />
      </p>
      <p>
        Tel. 01485 541755
        <br />
        Mob. 07833 321055
        <br />
        Email:{" "}
        <a href="mailto:ilexgardendesigns@uwclub.net">
          ilexgardendesigns@uwclub.net
        </a>
      </p>
      <Gallery />
    </Col>
  </Layout>
);

export default IndexPage;
